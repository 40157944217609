













































import { Component, Ref, Vue } from "vue-property-decorator";
import MySupplyItem from "@/components/MySupplyItem.vue";
import { IMineSupply, VoucherApi } from "@/api/voucher.api";
import SyntheticDialog from "@/components/SyntheticDialog.vue";
import EmptyBox from "@/components/EmptyBox.vue";
import { getNow, observer } from "@/utils/common.util";

@Component({
  components: { EmptyBox, SyntheticDialog, MySupplyItem },
})
export default class MySupply extends Vue {
  @Ref("supplyBody") private readonly supplyBodyRef!: HTMLDivElement;
  supply: IMineSupply[] = [];
  wideHigh = "";
  sort = 0;
  dialog = false;
  empty = false;

  handleSort(): void {
    const { sort } = this;
    this.sort = Number(!sort);
  }

  get supplyItems(): IMineSupply[] {
    const { sort } = this;
    return this.supply
      .sort((a, b) => b.supply_type - a.supply_type)
      .sort((a, b) => b.price - a.price)
      .sort((a, b) => {
        return sort ? b.character.value - a.character.value : b.time - a.time;
      });
  }

  get sortText(): string {
    return this.sort ? "按品质排序" : "按时间排序";
  }

  handleOpenPage(id: number, type: number): void {
    const name = type === 2 ? "MyVoucherEntityDetail" : "MyVoucherDetail";
    this.$router.push({
      name,
      params: { id: String(id) },
      query: { time: String(getNow()) },
    });
  }

  async loadMySupply(): Promise<void> {
    this.supply = await VoucherApi.getMineSupply();
    if (!this.supply.length) this.empty = true;
  }

  mounted(): void {
    observer("refreshInit", () => this.loadMySupply());
    const { clientWidth, offsetWidth } = this.supplyBodyRef;
    this.wideHigh = ((clientWidth || offsetWidth) - 32 - 16) / 3 + "px";
    this.loadMySupply();
  }
}
